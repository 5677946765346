import React, { useEffect, useState } from "react";
import {
    Breadcrumb,
    Col,
    Row,
    Card,
    Button,
    Modal,
    Form,
    FormGroup,
} from "react-bootstrap";
// import * as amount from "../../../data/amount/createamount";
import * as uniquenumber from "../../../data/uniqueNumber/createUniqueNumber";
import { CForm } from "@coreui/react";
import { useForm } from "react-hook-form";
import endpoint from "../../../context/endpoint";
import { ErrorAlert, SuccessAlert } from "../../../data/Toast/toast";

export default function CreateUniqueNumber() {
    const { register, handleSubmit, reset } = useForm();

    const [isLoading, setLoading] = useState(false);
    const [amountModal, setShowAmountModal] = useState(false);
    const [amountModalHeading, setAmountModalHeading] = useState("");
    const [uniqueNumber, setUniqueNumber] = useState("");
    const [datas, setDatas] = useState([]);
    const [open, setOpen] = useState(false);

    const [details, setDetails] = useState({
        unique_number: "",
        id: null, // Assuming you have an ID field for the amount
    });
    // Show amount modal
    const handleAppellantModal = () => {
        setShowAmountModal(true);
        // setSingleAppellant({});
        setAmountModalHeading("Add New Number");
    };

    // Create or edit amount
    const handleCreateNumber = async () => {
        setLoading(true);
        const data = new FormData();
        data.append("unique_number", uniqueNumber);

        await endpoint
            .post(`/unique-code/create/`, data)
            .then((res) => {
                SuccessAlert(res.data.message);
                setShowAmountModal(false);
                setLoading(false);
                getAllData();
                resetForm();
                setOpen(!open);
            })
            .catch((error) => {
                setLoading(false);
                ErrorAlert(error.response.data.description);
            });
    };

    const getAllData = async () => {
        await endpoint
            .get(`/unique-code/get-all`)
            .then((res) => {
                console.log("all unique number list", res.data.data);
                setDatas(res.data.data);
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        getAllData();
    }, []);

    const resetForm = () => {
        setUniqueNumber(""); // Reset unique number field
    };

    const onClose = () => {
        setOpen(false);
        // setDeleteOpen(false);
    }

    // console.log(resetForm());
    return (
        <div>
            <Row>
                <Col
                    md={12}
                    className="col-12">
                    <Card>
                        <Card.Header>
                            <Col className="text-beginning">
                                <Card.Title
                                    as="h3"
                                    style={{ color: "#0A7E51", fontWeight: 900 }}>
                                    UNIQUE CODE SETUP
                                </Card.Title>
                            </Col>
                            <Col className="text-end">
                                <Button
                                    className="btn"
                                    type="submit"
                                    variant="primary"
                                    onClick={(e) => { 
                                        // setNameToVerify(data.surname+' '+data.first_name+' '+data.middle_name)
                                        // setVerifyId(row.id)
                                        setOpen(!open)
                                  }}
                                    style={{ color: "#0A7E51", fontWeight: 900 }}>
                                    <span className="fe fe-plus"></span>
                                    Generate Unique Code
                                </Button>
                            </Col>
                        </Card.Header>
                        <Card.Body>
                            <div className="">
                                <div className="">
                                    <uniquenumber.CreateUniqueNumber
                                        datas={datas}
                                        getAllData={getAllData}
                                    />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Modal show={amountModal}>
                <Modal.Header>
                    <Card.Title as="h3">{amountModalHeading}</Card.Title>
                </Modal.Header>
                <CForm
                    // onSubmit={handleSubmit(handleCreateNumber)}
                    className="row g-3 needs-validation">
                    <Modal.Body>
                        <Card>
                            <Card.Body>
                                <Col
                                    lg={12}
                                    md={12}>
                                    <FormGroup>
                                        <label htmlFor="exampleInputname">
                                            Unique Number <span style={{ color: "red" }}>*</span>
                                        </label>
                                        <Form.Control
                                            type="text"
                                            name="uniqueNumber"
                                            defaultValue={uniqueNumber}
                                            onChange={(e) => setUniqueNumber(e.target.value)}
                                            className="form-control"
                                        />
                                    </FormGroup>
                                </Col>
                                <br />
                            </Card.Body>
                        </Card>

                        <Modal.Footer>
                            <Button
                                variant="danger"
                                className="me-1"
                                onClick={() => setShowAmountModal(false)}>
                                Close
                            </Button>
                            <Button
                                variant="primary"
                                type="submit"
                                className="me-1">
                                <span className="fe fe-plus"></span>
                                Save
                            </Button>
                        </Modal.Footer>
                    </Modal.Body>
                </CForm>
            </Modal>

            <Modal show={open}>
                <Modal.Body className="text-center p-4">
                    <Button
                    onClick={onClose}
                    className="btn-close"
                    variant=""
                    > 
                    x
                    </Button>
                    <i className="fe fe-check-circle fs-100 text-success lh-1 mb-4 d-inline-block"></i>
                    {/* <h4 className="text-success mb-4">Alert!</h4> */}
                    <p className="mb-4 mx-4" style={{fontSize: "18px", fontWeight: "", color: "red"}}>
                        <i>
                            Are you sure you want to generate unique code? this should be 
                            done when a new enrolment batch is coming up... 
                        </i>
                        <br />
                    </p>

                    <button className="btn btn-danger pd-x-25 m-1" onClick={onClose}>
                    Cancel
                    </button>
                    <button className="btn btn-success pd-x-25 " onClick={(e) => handleCreateNumber()}>
                    Generate
                    </button>
                </Modal.Body>
            </Modal>
        </div>
    );
}
