import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import endpoint from "../../context/endpoint";
import avatarNoImg from "../Scn-Components/avatarNoImg3.jpg";
import './enrollment_confirmation_print.css'

const EnrollmentConfirmationPrint = () => {
    const [loading, setLoading] = useState(false);
    const [enroll, setEnroll] = useState({});
    const [previewImage, setPreviewImage] = useState(null);
    const params = useParams();
    useEffect(() => {
        getUser();
    }, []);

    let myformattedDate = '';

    function formatDatePlacement(dateString) {
        const date = new Date(dateString);
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();
        function formatDatePlacement(dateString) {
            const date = new Date(dateString);
            const day = date.getDate();
            const monthIndex = date.getMonth();
            const year = date.getFullYear();

            const monthNames = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ];

            myformattedDate = `${day} ${monthNames[monthIndex]} ${year}`;
            return myformattedDate;
        }

        return myformattedDate;
    }


    const getTodaysDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, "0");
        const day = String(today.getDate()).padStart(2, "0");
        return `${day}-${month}-${year}`;
    };

    const getUser = async () => {
        setLoading(true);
        await endpoint
            .get(`/enrollment/show/graduand/${params.id}`)
            .then((res) => {
                setLoading(false);
                setEnroll(res.data.data);
                console.log("res data", res.data.data)
                if (
                    res.data.data.passport_url == null ||
                    res.data.data.passport_url == ""
                ) {
                    setPreviewImage(avatarNoImg);
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    return (<>
        {enroll &&
            <div id="print">
                <link
                    href="https://fonts.googleapis.com/css2?family=Tangerine:wght@700&display=swap"
                    rel="stylesheet"
                />
                <div className="certificate-container print-only">

                    
                    <div className="certificate-header">
                        {/* Company Logo */}
                        <img src="https://lawyerenrollment.com.ng/static/media/scnlogo.263b07e92ccbe2b69af3.png" alt="Company Logo" className="logo" />
                        {/* Header Text */}
                        <div>Supreme Court of Nigeria</div>
                        {/* User Passport */}

                        <div className="passport-section d-flex flex-column align-items-center">
                            {/* <img src="https://enrollments3.s3.af-south-1.amazonaws.com/1714652691388.jpeg" alt="User Passport" className="passport" /> */}
                            <img src={enroll.passport_url} alt="User Passport" className="passport" />
                            <div className="enrollment-number">
                                {enroll.Profile?.enrollment_number}
                            </div>
                        </div>
                    </div>
                    {/* <hr /> */}
                    <div className="certificate-title">
                        Confirmation of Enrollment
                    </div>
                    <div className="certificate-body">
                        This is to certify that <br />
                        <span className="recipient-name">
                            {enroll.surname + " " + (enroll.first_name ? enroll.first_name : " ") + " " + (enroll.middle_name ? enroll.middle_name : " ")}
                        </span>
                        <br />
                        has been successfully enrolled to practice as a Barrister and Solicitor in the
                        <br />
                        <strong>
                            Supreme Court of Nigeria.
                        </strong> <br />
                        {/* on the 25th of November, 2024. */}
                    </div>
                    <div className="details d-flex justify-content-center gap-4">
                        <p>Enrollment Number: &nbsp;
                            <strong>
                                {enroll.Profile?.enrollment_number}
                            </strong>
                        </p>
                        <p>Date of Birth: &nbsp;
                            <strong>
                                {(enroll.Profile?.formatted_date_of_birth)}
                            </strong>
                        </p>
                        <p>Date of Placement: &nbsp;
                            <strong>
                                {(enroll.Profile?.formatted_date_of_placement)}
                            </strong>
                        </p>
                    </div>

                    <div className="certificate-footer">
                        <div className="signature-section">
                            <div className="signature">
                                <img src={enroll.Profile?.Registrar?.signature} alt="registrar" srcset="" />
                                {/* <div className="signature-line" /> */}
                                <div className="signature-name">
                                    {enroll.Profile?.Registrar?.surname + " " + (enroll.Profile?.Registrar?.first_name) + " " + (enroll.Profile?.Registrar?.middle_name)}
                                </div>
                                <div className="signature-name">Chief Registrar,</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='text-center'>
                        {/* <button onClick={() => window.print()} className="btn btn-primary m-2 hideBtn"> <span className="fa fa-print"></span>
                            Print</button> */}
                        <button onClick={() => window.print()} className="btn btn-primary m-2 hideBtn">
                            <span className="fa fa-print"></span> Print
                        </button>
                    </div>
                </div>
            </div>
        }
    </>)
}

export default EnrollmentConfirmationPrint